import React, {useState, useContext} from 'react';
import {Form, Button, Modal, Alert} from "react-bootstrap";
import  {TeamDataContext } from '../TeamDataContext';
import  {PlayerDataContext } from '../PlayerDataContext';
import { ToastContext } from '../ToastContext';
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";


const LoginForm = (props) => {

    const [, , , updateTeamData] = useContext(TeamDataContext);
    const [playerData] = useContext(PlayerDataContext);
    const [pushToast] = useContext(ToastContext);
    const history = useHistory();

     // Login form 
    const { register, handleSubmit, errors } = useForm();

    // Error
    const [error, setError] = useState({
        show: false,
        text: ""
    });

    const handleError = (bool, text="") => {
        setError({ show: bool, text: text});
    }

     // Triggered by login (on clicking button)
    const doLogin = (form) => {

        let status = ""

        fetch('/api/teamlogin', {
            method: 'GET',
            headers: {
                'Authorization': 'Basic '  + form.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => {
            status = response.status
            if (response.ok) {
                return response.json()
            } else {
                return response.text()
            }
        })
        .then(data => {
            if (status === 200){ 
                console.log(data)
                updateTeamData(data.id, form.token)
                history.push('/')

                if (playerData.length === 0) {
                    pushToast({
                        header: "You have no player for games!",
                        message: "Go to 'Team Profile' in navigation bar and create a new player", 
                        type: "warning",
                        autoClose: 20000
                    })
                }
                
            } else {
                console.log(data)
                handleError(true, "Token invalid")
            }
        })
        .catch((error) => {
            handleError(true, error.message);
        })
    };

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Team Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Form.Group >
                        <Form.Label>Token</Form.Label>
                        <Form.Control placeholder="Enter Token" name="token" ref={register({ required: true })} /> 
                        {errors.token && 
                            <Form.Text style={{"color": "red"}}>
                                This field is required
                            </Form.Text>}
                    </Form.Group> 
                    <Alert variant="danger" hidden={!error.show}>
                        {error.text}
                    </Alert>  
                    
                    <Button variant="success" onClick={handleSubmit(doLogin)}>
                        Sign in
                    </Button>
                </Form>
                <hr />
                <p style={{"textAlign": "right"}}>
                    Don’t have a token?
                    <button className="switch-view-button" type="button" onClick={props.handleIsLogin}>
                        Sign up
                    </button>
                </p>
            </Modal.Body>
        </>
    );
}

export default LoginForm 