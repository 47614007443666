import React, {useState} from "react";
import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";

const ReportButton = props => {

    // init header and body
    const report = ["mailto:tobias.loch@tu-berlin.de?subject=Report%20Erroneous%20Game%20State&body="]
    console.log(props.state)

    // Modal
    const [showReport, setShowReport] = useState(false);
    const handleClose = () => setShowReport(false);
    const handleShow = () => setShowReport(true);

    // Form - On Submit
    const { register, handleSubmit } = useForm();
    const handleSend = (form) => {

        // Input
        if (form.description.length > 0) {
            report.push(encodeURIComponent("DESCRIPTION: \n\n'" + form.description + "'\n\n\n"))
        }

        // Last Move this.props.playerState
       if (props.state.currentMove.move !== "") {
            report.push(encodeURIComponent("ABOUT MY LAST MOVE: \n \n"))

            report.push(encodeURIComponent(" - Move: '" + props.state.currentMove.move + "' \n"))
            report.push(encodeURIComponent(" - FEN was: '" + props.state.currentMove.fen + "'\n"))
            report.push(encodeURIComponent(" - Server answer: '" + props.state.currentMove.valid + "'\n"))
            report.push(encodeURIComponent(" - Reason: '" + props.state.currentMove.reason + "'\n\n"))
            report.push(encodeURIComponent(" - NOTE: Please check Moves History in GAME INFORMATION \n\n\n"))
       }

        // General Game Information
        if (props.state.initState !== null) {
            report.push(encodeURIComponent("GAME INFORMATION: \n \n"))
            report.push(encodeURIComponent(" - Game Type: " + props.state.initState.type + "\n"))
            report.push(encodeURIComponent(" - Current FEN: " + props.state.lastFEN + "\n"))
            report.push(encodeURIComponent(" - I Am Player: " + props.state.player.state + " -> (0 = not logged in, 1 = w, 2 = b)\n"))
            report.push(encodeURIComponent(" - Current Move Number: " + props.state.currentMoveNumber + "\n"))
            report.push(encodeURIComponent(" - Game State: " + props.state.initState.state.state + "\n"))
            report.push(encodeURIComponent(" - Winner: " + props.state.initState.state.winner + "\n"))
            report.push(encodeURIComponent(" - Messages: " + JSON.stringify(props.state.messages, null, 2) + "\n\n"))
            report.push(encodeURIComponent(" - Moves History: " + JSON.stringify(props.state.moves, null, 2) + "\n\n"))
            report.push(encodeURIComponent(" - End: " + JSON.stringify(props.state.end, null, 2) + "\n\n"))
        }

        window.location.href = report.join("")
        handleClose()
    }


    return (
        <>
            <Button variant="danger" onClick={handleShow}>
                Report
            </Button>

            <Modal show={showReport} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{"fontWeight": "bold"}}>Is there something wrong? Send an error report to our ruleserver team! </p>
                    <p>
                        Clicking on "Open Mail App" will open your default mail application. We will generate all information
                        into the body. Just a description and send it with your mail application.
                    </p> 
                    
                    <br />
                    <Form.Group>
                        <Form.Label>Add description:</Form.Label>
                        <Form.Control placeholder="Optional" name="description" ref={register()}/>
                    </Form.Group> 
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="success" onClick={handleSubmit(handleSend)}>
                    Open Mail App
                </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
}

export default ReportButton;