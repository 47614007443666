import React from "react";
import Game from "../../components/GameInfo/game";

const SingleGame = (props) => {
  
  return (
  <>
    <Game gameId={props.match.params.id}/>;
  </>
  )};

export default SingleGame;
