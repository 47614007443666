import React, {useState, useContext} from 'react';
import {Form, Button, Modal, Alert, Table, OverlayTrigger, Tooltip, Card} from "react-bootstrap";
import { TeamDataContext } from '../TeamDataContext';
import { ToastContext } from '../ToastContext';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from "react-hook-form";

const RegistrationForm = (props) => {

    const [, setTeamData] = useContext(TeamDataContext);
    const [pushToast] = useContext(ToastContext);
    const history = useHistory();

    // Registration Form
    const { register, handleSubmit, errors } = useForm();

    // success
    const [successData, setSuccessData] = useState(null)

    // Error
    const [error, setError] = useState({
        show: false,
        text: ""
    });

    const handleError = (bool, text="") => {
        setError({ show: bool, text: text});
    }

    // Tooltip
    const [tooltip, setTooltip] = useState("Click to copy!")


    // Triggered by registration (on clicking button)
    const doRegistration = (form) => {

        let status = ""

        fetch('/api/teams', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(form)
        })
        .then(response => {
            status = response.status
            if (response.ok) {
                return response.json()
            } else {
                return response.text()
            }
        })
        .then(data => {
            if (status === 201) {
                console.log(data)
                delete data.valid
                setSuccessData({...data, ...form})
            } else {
                handleError(true, data)
            }
        })
        .catch((error) => {
            handleError(true, error.message);
        })
    };

    const doSave = () => {
        setTeamData(successData)
        history.push('/')
        pushToast({
            header: "You have no player for games!",
            message: "Go to 'Team Profile' in navigation bar and create a new player", 
            type: "warning",
            autoClose: 20000
        })
    }

    return (
        <>
            <Modal.Header closeButton={successData === null ? true : false}>
                <Modal.Title>Team Registration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {successData === null ? 
                <>
                    <Form onSubmit={e => { e.preventDefault(); }}>
                        <Form.Group>
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control placeholder="Enter Team Name" name="name" ref={register({ required: true })} /> 
                            {errors.name && 
                            <Form.Text style={{"color": "red"}}>
                                This field is required
                            </Form.Text>}
                        </Form.Group> 
                        <Form.Group>
                            <Form.Label>ISIS Name</Form.Label>
                            <Form.Control placeholder="Enter ISIS Name (Optional)" name="isisName" ref={register()} />
                        </Form.Group> 
                        <Form.Group>
                        <Form.Label>Game Type</Form.Label>
                            <Form.Control 
                                as="select"
                                name="type" 
                                defaultValue="Choose Game Type..."
                                ref={register({ required: true })}
                                >
                                <option value="" hidden>Choose Game Type...</option>
                                <option value="jumpSturdy">Jump Sturdy</option>
                                <option value="racingKings">Racing Kings</option>
                            </Form.Control>
                            {errors.type && 
                            <Form.Text style={{"color": "red"}}>
                                This field is required
                            </Form.Text>}
                        </Form.Group> 
                        <Alert variant="danger" hidden={!error.show}>
                                {error.text}
                        </Alert>
                        <Button variant="success" onClick={handleSubmit(doRegistration)}>
                            Sign up
                        </Button>
                    </Form>
                    <hr />
                    <p style={{"textAlign": "right"}}>
                        Have a token?
                        <button className="switch-view-button" type="button" onClick={props.handleIsLogin}>
                            Sign in
                        </button>
                    </p>
                </> :
                <>
                    <h4>Hello, {successData.name}!</h4>
                    <p>Your team registration was successful! Here are your team information.</p>
                    <Card>
                        <Card.Body>
                            <Table borderless size="sm">
                                <tbody>
                                    <tr>
                                        <td style={{"fontWeight": "bold"}}>Team Name:</td>
                                        <td>{successData.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{"fontWeight": "bold"}}>ISIS Name:</td>
                                        <td>{successData.isisName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{"fontWeight": "bold"}}>Game Type:</td>
                                        <td>{successData.type.replace(/([A-Z])/g, ' $1').replace(/^./, str => { return str.toUpperCase(); })}</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <h5 style={{"margin": "20px 0"}}>Your team token:</h5>
                    <OverlayTrigger
                        key='top'
                        placement='top'
                        onEnter={() => setTooltip("Click to copy!")}
                        overlay={
                        <Tooltip>
                            {tooltip}
                        </Tooltip>
                        }
                    >
                        <CopyToClipboard text={successData.token} >
                            <Button variant="info" block onClick={() => setTooltip("Copied!")}>
                                {successData.token}
                            </Button>
                        </CopyToClipboard>
                    </OverlayTrigger>
                    
                    <p style={{"marginTop": "20px"}}>The team token is needed for the login. Click on token to copy to clipboard. Please make sure to keep your token in secret. :)</p>
                    <hr />
                    <Button variant="success" onClick={doSave}>
                        kkThxBye
                    </Button>
                    

                </>
            }
            </Modal.Body>
        </>
    )
}

export default RegistrationForm