import React, { useState, useCallback, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import SyncLoader from 'react-spinners/SyncLoader';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import './index.css';

const CreateGame = (props) => {
    const [game, setGame] = useState({
        name: '',
        type: '',
        players: {
            playerA: {
                id: '',
                timeout: 0,
                initialTimeBudget: 0,
            },
            playerB: {
                id: '',
                timeout: 0,
                initialTimeBudget: 0,
            },
        },
        settings: {
            initialFEN: '',
        },
    });

    const [teams, setTeams] = useState([]);

    const [players, setPlayers] = useState({
        players_white: [],
        players_black: [],
    });

    const [loading, loadGames] = useState(false);
    const [validated, setValidated] = useState(true);
    const [fetchError, setFetchError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (loading && validated) {
            const submitGame = game;

            //Client Side defaulting
            if (submitGame.settings.initialFEN === '') {
                if (submitGame.type === 'jumpSturdy') {
                    submitGame.settings.initialFEN = '1bbbbbb1/1bbbbbb1/8/8/8/8/1BBBBBB1/1BBBBBB1 w - - 0 1';
                } else {
                    submitGame.settings.initialFEN = '8/8/8/8/8/8/krbnNBRK/qrbnNBRQ w - - 0 1';
                }
            }

            if (submitGame.settings.initialFEN === '') {
                submitGame.settings.initialFEN = null;
            }

            fetch('/api/games', {
                method: 'POST',
                body: JSON.stringify(submitGame),
                headers: new Headers({
                    'Content-Type': 'application/json',
                }),
            })
                .then((res) => res.json())
                .then((res) => {
                    loadGames(false);
                    history.push('/game/' + res.id);
                })
                .catch((error) => {
                    loadGames(false);
                    console.log(error);
                    setFetchError(true);
                });
        }
    }, [game, history, loading, validated]);

    useEffect(() => {
        if (
            players.players_black.length === 0 &&
            players.players_white.length === 0 &&
            game.players.playerA.id !== '' &&
            game.players.playerB.id !== ''
        ) {
            setGame({
                ...game,
                players: {
                    playerA: { ...game.players.playerA, id: '' },
                    playerB: { ...game.players.playerB, id: '' },
                },
            });
        }
    }, [game, players]);

    let validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        type: Yup.string()
            .max(255)
            .matches(/(jumpSturdy|racingKings)/)
            .required(),
        players: Yup.object({
            playerA: Yup.object({
                id: Yup.string().max(255).required(),
                timeout: Yup.number()
                    .min(0)
                    .max(game.players.playerA.initialTimeBudget)
                    .required(),
                initialTimeBudget: Yup.number().min(0).required(),
            }),
            playerB: Yup.object({
                id: Yup.string().max(255).required(),
                timeout: Yup.number()
                    .min(0)
                    .max(game.players.playerB.initialTimeBudget)
                    .required(),
                initialTimeBudget: Yup.number().min(0).required(),
            }),
        }),
        settings: Yup.object({
            initialFEN: Yup.string().max(255),
        }),
    });

    const validateInputs = useCallback(() => {
        const submitGame = game;

        return validationSchema.validate(submitGame);
    }, [game, validationSchema]);

    const handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        let value = '';

        setValidated(true);

        if (name === 'type' || name === 'playerA_id' || name === 'playerB_id') {
            value = target.options[target.selectedIndex].value;
        } else {
            value = target.value;
        }

        let playerAttributes = name.split('_');

        if (
            playerAttributes.length === 2 ||
            playerAttributes[0] === 'playerA' ||
            playerAttributes[0] === 'playerB'
        ) {
            let player = playerAttributes[0];
            let property = playerAttributes[1];

            if (property === 'initialTimeBudget' || property === 'timeout') {
                try {
                    value = parseInt(value);
                } catch (error) {
                    console.log(error);
                    return;
                }
            }

            setGame({
                ...game,
                players: {
                    ...game.players,
                    [player]: { ...game.players[player], [property]: value },
                },
            });
        } else if (name === 'initialFEN') {
            setGame({ ...game, settings: { ...game.settings, [name]: value } });
        } else {
            setGame({ ...game, [name]: value });
        }
    };

    const handleTeamChange = (event) => {
        const target = event.target;
        const name = target.name;
        const teamid = target.options[target.selectedIndex].value;

        fetch('/api/players')
            .then((response) => response.json())
            .then((res) => {
                let newPlayers = [];
                let newPlayer = {};

                for (let key in res.items) {
                    if (res.items[key].team === teamid) {
                        newPlayer = res.items[key];
                        newPlayer.playerid = key;

                        newPlayers.push(newPlayer);
                    }
                }
                setPlayers({ ...players, [name]: newPlayers });
            })
            .catch((error) => {
                console.log(error);
                setFetchError(true);
            });
    };

    const handleGameTypeChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.options[target.selectedIndex].value;

        setGame({ ...game, [name]: value });

        fetch('/api/teams')
            .then((response) => response.json())
            .then((res) => {
                let newTeams = [];
                let newTeam = {};

                for (let key in res.items) {
                    if (res.items[key].type === value) {
                        newTeam = res.items[key];
                        newTeam.teamid = key;

                        newTeams.push(newTeam);
                    }
                }
                setTeams(newTeams);
            })
            .then(() => {
                resetPlayers();
            })
            .catch((error) => {
                console.log(error);
                setFetchError(true);
            });
    };

    const handleSubmitClick = (event) => {
        event.preventDefault();

        validateInputs()
            .then(() => {
                loadGames(true);
            })
            .catch((error) => {
                console.log(error);
                setValidated(false);
            });
    };

    const resetPlayers = () => {
        setPlayers({ players_white: [], players_black: [] });
    };

    return (
        <React.Fragment>
            <div
                className={
                    'loading-overlay' + (loading ? ' d-flex' : ' d-none')
                }>
                <SyncLoader
                    loading={loading}
                    size={10}
                    color={'#4CAF50'}></SyncLoader>
                <h2 style={{ marginTop: '20px' }}>Creating Game ...</h2>
            </div>
            <Container fluid="md">
                <Col md={12} lg={{ size: 8 }}>
                    <h1 className="text-center m-4">Create Game</h1>
                    <Form method="post" onSubmit={handleSubmitClick}>
                        <Form.Group>
                            <Form.Label>Game Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Spielnamen vergeben"
                                name="name"
                                value={game.name}
                                onChange={handleInputChange}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Game Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="type"
                                onChange={handleGameTypeChange}
                                defaultValue="Choose Game Type...">
                                <option hidden>Choose Game Type...</option>
                                <option value="jumpSturdy">Jump Sturdy</option>
                                <option value="racingKings">
                                    Racing Kings
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Row>
                            <Col xs={12} md={6}>
                                <div className="px-4 mx-2 pt-2 my-4 player_col white_player_col">
                                    <Form.Group>
                                        <Form.Label>White Team</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder="Rotes Team"
                                            name="players_white"
                                            onChange={handleTeamChange}
                                            disabled={!teams.length > 0}
                                            defaultValue="Choose Team...">
                                            <option hidden>
                                                {teams.length > 0
                                                    ? 'Choose Team...'
                                                    : ''}
                                            </option>
                                            {teams.map((team) => (
                                                <option
                                                    value={team.teamid}
                                                    key={team.teamid}>
                                                    {team.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>White Player</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="playerA_id"
                                            disabled={
                                                !players.players_white.length >
                                                0
                                            }
                                            onChange={handleInputChange}
                                            defaultValue="Choose Player...">
                                            <option hidden>
                                                {players.players_white.length >
                                                0
                                                    ? 'Choose Player...'
                                                    : ''}
                                            </option>
                                            {players.players_white.map((e) => (
                                                <option
                                                    value={e.playerid}
                                                    key={e.playerid}>
                                                    {e.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Time Budget per Game
                                        </Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                placeholder="Time Budget"
                                                aria-describedby="timeBudget-group-A"
                                                name="playerA_initialTimeBudget"
                                                value={
                                                    game.players.playerA
                                                        .initialTimeBudget
                                                }
                                                onChange={handleInputChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="timeBudget-group-A">
                                                    Milliseconds
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Timeout per Move
                                        </Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                placeholder="Timeout"
                                                aria-describedby="timeout-group-A"
                                                name="playerA_timeout"
                                                value={
                                                    game.players.playerA.timeout
                                                }
                                                onChange={handleInputChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="timeout-group-A">
                                                    Milliseconds
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className="px-4 mx-2 pt-2 my-4 player_col black_player_col">
                                    <Form.Group>
                                        <Form.Label>Black Team</Form.Label>
                                        <Form.Control
                                            as="select"
                                            placeholder="Rotes Team"
                                            name="players_black"
                                            onChange={handleTeamChange}
                                            disabled={!teams.length > 0}
                                            defaultValue="Choose Team...">
                                            <option hidden>
                                                {teams.length > 0
                                                    ? 'Choose Team...'
                                                    : ''}
                                            </option>
                                            {teams.map((team) => (
                                                <option
                                                    value={team.teamid}
                                                    key={team.teamid}>
                                                    {team.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Black Player</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="playerB_id"
                                            disabled={
                                                !players.players_black.length >
                                                0
                                            }
                                            onChange={handleInputChange}
                                            defaultValue="Choose Player...">
                                            <option hidden>
                                                {players.players_black.length >
                                                0
                                                    ? 'Choose Player...'
                                                    : ''}
                                            </option>
                                            {players.players_black.map((e) => (
                                                <option
                                                    value={e.playerid}
                                                    key={e.playerid}>
                                                    {e.name}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Time Budget per Game
                                        </Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                placeholder="Time Budget"
                                                aria-describedby="timeBudget-group-B"
                                                name="playerB_initialTimeBudget"
                                                value={
                                                    game.players.playerB
                                                        .initialTimeBudget
                                                }
                                                onChange={handleInputChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="timeBudget-group-B">
                                                    Milliseconds
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Timeout per Move
                                        </Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="number"
                                                placeholder="Timeout"
                                                aria-describedby="timeout-group-B"
                                                name="playerB_timeout"
                                                value={
                                                    game.players.playerB.timeout
                                                }
                                                onChange={handleInputChange}
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="timeout-group-B">
                                                    Milliseconds
                                                </InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </Form.Group>
                                </div>
                            </Col>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>
                                Initial Game State (optional)
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="FEN-String"
                                name="initialFEN"
                                value={game.settings.initialFEN}
                                onChange={handleInputChange}></Form.Control>
                        </Form.Group>
                        <Form.Row
                            className="justify-content-end"
                            style={{ padding: '5px' }}>
                            <Button
                                variant="success"
                                type="submit"
                                disabled={loading}>
                                Create Game
                            </Button>
                        </Form.Row>
                    </Form>
                    <Alert
                        variant={'danger'}
                        className={
                            'mt-4' + (validated ? ' d-none' : ' d-block')
                        }>
                        Form validation failed. Please check your inputs.
                    </Alert>
                    <Alert
                        variant={'danger'}
                        className={
                            'mt-4' + (!fetchError ? ' d-none' : ' d-block')
                        }>
                        A fetch error occurred! Please check the console for
                        further details.
                    </Alert>
                </Col>
            </Container>
        </React.Fragment>
    );
};

export default CreateGame;
