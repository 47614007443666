import React, {useState, useContext} from 'react';
import {Form, Button, Alert, Table, Modal, OverlayTrigger, Tooltip, Card} from "react-bootstrap";
import {TeamDataContext} from '../TeamDataContext';
import {PlayerDataContext} from '../PlayerDataContext';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useForm } from "react-hook-form";

const NewPlayer = (props) => {

    // Context
    const [teamData] = useContext(TeamDataContext);
    const [, , updatePlayerData] = useContext(PlayerDataContext);

    // Player 
    const { register, handleSubmit, errors } = useForm();


    // Error
    const [error, setError] = useState({
        show: false,
        text: ""
    });
    const handleError = (bool, text="") => {
        setError({ show: bool, text: text});
    };

    // basicly the data u get from server on success
    const [successData, setSuccessData] = useState(null);

    // tooltip
    const [tooltip, setTooltip] = useState("");

    // on Submit
    const createPlayer = (form) => {

        let status = 0;

        fetch('/api/players', {
            method: 'POST',
            headers: { 
                'Authorization': 'Basic '  + teamData.token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(form)
        })
        .then(response => {
            status = response.status;
            if (response.ok) {
                return response.json();
            } else {
                return response.text();
            }
        })
        .then(data => {
            if (status === 201) {
                setSuccessData({...data, ...form});
            } else {
                handleError(true, data);
            }
        })
        .catch((error) => {
            handleError(true, error.message);
        });
    };

    // complete the submit 
    const doSave = () => {
        updatePlayerData();
        props.handleRegisterClose();
    }

    return (
        <>
        <Modal.Header closeButton={successData === null ? true : false}>
            <Modal.Title>Create New Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {successData === null ?
            <>
                <Form onSubmit={e => { e.preventDefault(); }}>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" ref={register({ required: true })} placeholder="Enter Player Name" />
                            {errors.name && 
                            <Form.Text style={{"color": "red"}}>
                                This field is required
                            </Form.Text>}
                        </Form.Group>
                        <Alert variant="danger" hidden={!error.show}>
                            {error.text}
                        </Alert>  
                        <Button variant="success" type="submit" onClick={handleSubmit(createPlayer)}>
                            Create
                        </Button>
                        <hr />
                        <p style={{"textAlign": "right"}}>
                            No need?
                            <button className="switch-view-button" type="button" onClick={props.handleRegisterClose}>
                                Back to Profile
                            </button>
                        </p>
                </Form> 
            </> :
            <>
                <h4>Hello, {successData.name}!</h4>
                    <p>Your player registration was successful! Here are your player information.</p>
                    <Card>
                        <Card.Body>
                            <Table borderless size="sm">
                                <tbody>
                                    <tr>
                                        <td style={{"fontWeight": "bold"}}>Name:</td>
                                        <td>{successData.name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{"fontWeight": "bold"}}>ID:</td>
                                        <td>{successData.id}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    <h5 style={{"margin": "20px 0"}}>Your player token:</h5>
                    <OverlayTrigger
                        key='top'
                        placement='top'
                        onEnter={() => setTooltip("Click to copy!")}
                        overlay={
                        <Tooltip>
                            {tooltip}
                        </Tooltip>
                        }
                    >
                        <CopyToClipboard text={successData.token} >
                            <Button variant="info" block onClick={() => setTooltip("Copied!")}>
                                {successData.token}
                            </Button>
                        </CopyToClipboard>
                    </OverlayTrigger>
                    <p style={{"marginTop": "20px"}}>
                        The player token is needed to verify 
                        your player to start and play games. Click on token to copy to clipboard.
                        Please make sure to keep your token in secret. :)
                    </p>
                    <hr />
                    <Button variant="success" onClick={doSave}>
                        kkThxBye
                    </Button>
                </>
            }
            </Modal.Body>
        </>
    );
}

export default NewPlayer