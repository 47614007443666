import React from 'react';
import './../GameInfo/board.css';
import Board from './Board';

const _ = require("lodash");

class RacingKings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squares: parseFEN(props.fen), // empty FEN in case not updated, maybe dirty!?
      lastToken: [-1, -1],
      tokenIsSelected: false,
      moves: []
    };
  }

  static getDerivedStateFromProps(props) {
		if (props.fen) {
      return {
        squares: parseFEN(props.fen),
      }
    }
		return null;
  }

  // calculate Move-String and hand it up
  sendMove(currentPos, nextPos) {
    function numToChar(num) {
      return String.fromCharCode(97 + num);
    }

    this.props.onMove(`${numToChar(currentPos[1])}${8 - currentPos[0]}${numToChar(nextPos[1])}${8 - nextPos[0]}`);
  }

  // check if target/chosen Square belongs to player
  isCorrectPlayer(squares, t) {

    const { player } = this.props;

    if (player === 1 && 
      (squares[t[0]][t[1]] && 
        squares[t[0]][t[1]].slice(0, 5) === 'white')) {
      return true;

    } else if (player === 2 && 
      (squares[t[0]][t[1]] && 
        squares[t[0]][t[1]].slice(0, 5) === 'black')) {
      return true;
    }
    return false;
  }

  // gets click number from square
  handleClick(clickedSquare) {
    //var squares = this.state.squares.slice();  // copy squares -> immutable
    const { lastToken, squares, tokenIsSelected } = this.state;

    var retState = {};

    // send a move
    // messy logic here, but it makes it possible to click around within
    // the own tokens
    if (tokenIsSelected &&
        ((squares[clickedSquare[0]][clickedSquare[1]] &&
         squares[clickedSquare[0]][clickedSquare[1]].slice(0, 2) !== 
         squares[lastToken[0]][lastToken[1]].slice(0, 2)) ||
         !squares[clickedSquare[0]][clickedSquare[1]])) {

      // hand up move from last Token to clicked Square
      this.sendMove(lastToken, clickedSquare)
      
      // deselect move
      retState = {...retState,
        tokenIsSelected: false,
        moves: []
      }

    // simply deselect if same token is clicked
    } else if (tokenIsSelected &&
                _.isEqual(lastToken, clickedSquare)) {
      retState = {...retState,
        tokenIsSelected: false,
        moves: []
      }

    // selects the token if board is playable and the clicked Square is a token
    // from playing character
    } else  if (this.isCorrectPlayer(squares, clickedSquare)) {
      retState = {...retState, 
        moves: this.moveGen(squares, clickedSquare),
        lastToken: clickedSquare,
        tokenIsSelected: true
      }
    }

    this.setState(retState);
  }

  moveGen(squares, chosen) {
    let moves = this.pseudoMoveGen(squares, chosen);

    moves = moves.filter(move => {
      let squaresCopy = new Array(squares.length);
        for (let i = 0; i < squaresCopy.length; i++) {
          squaresCopy[i] = new Array(squares[i].length);
          for (let j = 0; j < squaresCopy[i].length; j++) {
            squaresCopy[i][j] = squares[i][j];
          }
        }
      squaresCopy[move[0]][move[1]] = squaresCopy[chosen[0]][chosen[1]];
      squaresCopy[chosen[0]][chosen[1]] = null;
      let check = this.inCheck(squaresCopy);
      return !check;
    });

    console.log('moves: ', moves);
    
    return moves;

  }

  inCheck(squares) {
    for (let y = 0; y < squares.length; y++) {
      for (let x = 0; x < squares[y].length; x++) {
        if (squares[y][x] != null) {
          let moves = this.pseudoMoveGen(squares, [y,x]);
          for (let i = 0; i < moves.length; i++) {
            let element = moves[i];
            if (squares[element[0]][element[1]] != null)  {
              if (squares[element[0]][element[1]].slice(6) == 'king') return true;
            }
          }
        }
      }
    }
    return false;
  }

  // this calculates pseudo legal moves of chosen
  pseudoMoveGen(squares, chosen)  {

    // calulate if a move would be within board 
    const inBoard = (x, y, dir) => {
      return x + dir[0] >= 0 && x + dir[0] < 8 && y + dir[1] >= 0 && y + dir[1] < 8;
    }

    // walks in direction until obsticle
    const multipleMoves = (x, y, dirs) => {
      for (let dir of dirs) {
        let xwalk = x;
        let ywalk = y;
        while (inBoard(xwalk, ywalk, dir) &&
                !isOwn(xwalk, ywalk, dir) &&
                !isOpponent(xwalk, ywalk, dir)) {
          xwalk += dir[0];
          ywalk += dir[1];
          moves.push([ywalk, xwalk]);
        }

        // if the obsticle is an opponent, you can
        // move there
        if (inBoard(xwalk, ywalk, dir) &&
            isOpponent(xwalk, ywalk, dir)) {
          xwalk += dir[0];
          ywalk += dir[1];
          moves.push([ywalk, xwalk]);
        }
      }
    }

    // within a single Move you can go everywhere
    // exept theres an own token
    const singleMoves = (x, y, dirs) => {
      for (let dir of dirs) {
        if (inBoard(x, y, dir) &&
            !isOwn(x, y, dir)) {
          moves.push([y + dir[1], x + dir[0]]);
        }
      }
    }
    
    // checks if there is an opponent in that direction
    const isOpponent = (x, y, dir) => {
      let opponent = (squares[chosen[0]][chosen[1]].slice(0, 2) === 'wh' ? 'bl' : 'wh');
      return squares[y + dir[1]][x + dir[0]] &&
              squares[y + dir[1]][x + dir[0]].slice(0, 2) === opponent;
    }

    const isOwn = (x, y, dir) => {
      let own = squares[chosen[0]][chosen[1]].slice(0,2);
      return squares[y + dir[1]][x + dir[0]] &&
              squares[y + dir[1]][x + dir[0]].slice(0, 2) === own;
    }

    let moves = [];
    const [y, x] = chosen;

    // directions
    let horVertDirs = [[-1, 0], [0, 1], [1, 0], [0, -1]];
    let angleDirs = [[-1, 1], [1, 1], [1, -1], [-1, -1]];
    let knightDirs = [[-2, -1], [-1, -2], [1, -2], [2, -1],
                      [-2, 1], [-1, 2], [1, 2], [2, 1]];

    // digest the moves for the particular token
    if (squares[chosen[0]][chosen[1]].slice(6) === 'king') {
      singleMoves(x, y, horVertDirs);
      singleMoves(x, y, angleDirs);
    } else if (squares[chosen[0]][chosen[1]].slice(6) === 'queen') {
      multipleMoves(x, y, horVertDirs);
      multipleMoves(x, y, angleDirs);
    } else if (squares[chosen[0]][chosen[1]].slice(6) === 'rook') {
      multipleMoves(x, y, horVertDirs);
    } else if (squares[chosen[0]][chosen[1]].slice(6) === 'knight') {
      singleMoves(x, y, knightDirs);
    } else if (squares[chosen[0]][chosen[1]].slice(6) === 'bishop') {
      multipleMoves(x, y, angleDirs);
    }

    return moves;
  }

  render() {
    const { squares, lastToken, tokenIsSelected, moves } = this.state;

    return (
      <div className="game">
        <div className="game-board">
          <Board 
            squares={squares}
            onClick={(i) => this.handleClick(i)}
            clicked={lastToken}
            isClicked={tokenIsSelected}
            moves={moves}
          />
        </div>
      </div>
    );
  }
}

function unifyChar(char) {

  var token = char === char.toUpperCase()
    ? 'white_'
    : 'black_';

  if (char.toLowerCase() === 'r') {
    token += 'rook';
  } else if (char.toLowerCase() === 'n') {
    token += 'knight';
  } else if (char.toLowerCase() === 'b') {
    token += 'bishop';
  } else if (char.toLowerCase() === 'q') {
    token += 'queen';
  } else if (char.toLowerCase() === 'k') {
    token += 'king';
  } else if (char.toLowerCase() === 'p') {
    token += 'pawn';
  }

  return token;
}


function parseFEN(fen) {
  
  fen = fen.split(' ')[0];
  var rows = fen.split('/');

  var board = new Array(rows.length);

  for (let y = 0; y < rows.length; y++) {
    board[y] = new Array(8).fill(null);
    var chars = rows[y].split('');
    let x = 0;
    for (let i = 0; i < chars.length; i++) {
      
      // if not an number, then it must be a char
      if (isNaN(chars[i])) {
        board[y][x] = unifyChar(chars[i]);
        x++;

      // if not move on char[i] times
      } else {
        x += Number(chars[i]);
      }
    }
  }
  return board;
}

export default RacingKings;