import React from 'react';
import "./index.css";

const CollapseButton = (props) => {
	return (
		<div style={{display: "flex", justifyContent: "center"}} className={props.mode}>
			<button style={btnStyle} onClick={() => props.onCollapseClick(props.list)}>
				<h2 style={btnTextStyle}>{props.list.title} </h2>
				<svg style={{verticalAlign: "top"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>		
			</button>
		</div>
	);
}

const btnStyle = {
	border: "none",
	background: "none"
}

const btnTextStyle = {
	fontWeight: "300",
	margin: "0",
	display: "inline"
}
export default CollapseButton;