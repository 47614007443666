import React, {useState, createContext} from "react";

export const ToastContext = createContext();

export const ToastProvider = (props) => {

    /*
        USAGE:

        First:
        - import { ToastContext } from <path to ToastContext>;

        Inside Class/Function Component:
        - const [setToast] = useContext(ToastContext);

        Trigger Toast:
        - pushToast{ obj }
        - obj = {
            header: <string>                            // header ... text above message
            message: <string>                           // message inside Toast
            type: "error" || "success" || "warning"     // change the color of Toast, based on https://getbootstrap.com/docs/4.1/utilities/colors/
            autoClose: <int>                            // auto close toast in <int> ms (default = 500ms)
        }

        example -> see Logout.js 

    */

    // init state toast
    const [toast, setToast] = useState([])

    console.log("INIT", toast)

    const pushToast = (obj) => {
        console.log("MYPUSH", obj.autoClose)
        const date = new Date()

        if (obj.autoClose !== undefined ) {
            obj.timeout = obj.autoClose
        } else {
            obj.autoClose = 3000
            obj.timeout = 3000
        }

        obj.timeStart = date
        obj.id = Math.random().toString(36).substr(2, 9)
        toast.map(t => {
            console.log(t.timeout)

            if (t.timeout <= 0) {
                return t.timeout = 0;  
            } else{
                console.log(t.autoClose - Math.abs(date - t.timeStart))
                return t.timeout = t.autoClose - Math.abs(date - t.timeStart)
            }

        })
        setToast(toast => [...toast, obj])
        console.log("AFTER ADD",toast)
    }

    const closeToast = (id) => {
        
        console.log("BEOFERE SLICE", toast)
        setToast(toast => toast.filter(obj => obj.id !== id)) 
        console.log("After SLICE", toast)
    }



    // Got message? -> activate Toast in Notification.js
    /*
    useEffect(() => {
        if (toast.message !== "") {
            setShowToast(true)
        } 
    }, [toast])
    */
    /*
    useEffect(() => {
        if (showToast === false) {
            setToast({
                header: "",
                message: "", 
                type: "",
            })
        } 
    }, [showToast])
    */


    return (
        <ToastContext.Provider value={[pushToast, toast, closeToast]}>
            { props.children }
        </ToastContext.Provider>
    );
}