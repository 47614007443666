import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import './index.css'

const Login = () => {
    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Switcher Login <-> Registration view
    const [isLogin, setIsLogin] = useState(true);
    const handleIsLogin = () => setIsLogin(!isLogin);

    return (
        <>
            <Button
                variant="outline-secondary"
                className="header-login-button mx-4"
                onClick={handleShow}>
                Team Login
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static">
                {isLogin ? (
                    <LoginForm handleIsLogin={handleIsLogin} />
                ) : (
                    <RegistrationForm handleIsLogin={handleIsLogin} />
                )}
            </Modal>
        </>
    );
};

export default Login;
