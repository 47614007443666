import React, { Component } from 'react';
import GameCard from '../../components/GameCard';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Pagination from 'react-bootstrap/Pagination';
import CollapseButton from '../../components/CollapseButton/CollapseButton';
import SyncLoader from 'react-spinners/SyncLoader';
import './index.css';

class Home extends Component {
    state = {
        lists: [
            {
                name: 'running',
                title: 'Running Games',
                games: [],
                badge: {
                    color: '#4CAF50',
                    text: 'Live',
                },
                page: 1,
                totalItems: 0,
                extended: true,
                isLoading: true,
            },
            {
                name: 'planned',
                title: 'Planned Games',
                games: [],
                badge: {
                    color: '#BDBDBD',
                    text: 'Planned',
                },
                page: 1,
                totalItems: 0,
                extended: false,
                isLoading: false,
            },
            {
                name: 'completed',
                title: 'Finished Games',
                games: [],
                badge: {
                    color: '#f44336',
                    text: 'Concluded',
                },
                page: 1,
                totalItems: 0,
                extended: false,
                isLoading: false,
            },
        ],
    };

    async componentDidMount() {
        this.mounted = true;

        for (let list of this.state.lists) {
            if (list.extended && list.isLoading) {
                await this.fetchGames(list.name);
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        return (
            <Container fluid="lg">
                <Row className="mt-4">
                    <Col lg={{ span: 1 }}>
                        <Button
                            className="refreshButton"
                            variant="light"
                            onClick={this.handleRefreshButtonClick}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-refresh-ccw">
                                <polyline points="1 4 1 10 7 10"></polyline>
                                <polyline points="23 20 23 14 17 14"></polyline>
                                <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path>
                            </svg>
                        </Button>
                    </Col>
                    <Col lg={{ span: 10 }}>
                        {this.state.lists.map((li) => (
                            <React.Fragment key={li.name}>
                                <CollapseButton
                                    list={li}
                                    onCollapseClick={this.handleCollapseClick}
                                    mode={
                                        li.extended ? 'extended' : 'collapsed'
                                    }></CollapseButton>
                                {li.extended && li.isLoading ? (
                                    <div className="home-loading">
                                        <SyncLoader
                                            loading={li.isLoading}
                                            size={10}
                                            color={'#4CAF50'}></SyncLoader>
                                    </div>
                                ) : null}
                                {li.extended &&
                                li.games.length === 0 &&
                                !li.isLoading ? (
                                    <p className="no-games-info lead">
                                        No Games found!
                                    </p>
                                ) : null}
                                {li.extended &&
                                li.games.length > 0 &&
                                !li.isLoading
                                    ? li.games.map((game) => (
                                          <GameCard
                                              key={game.id}
                                              mode={li.mode}
                                              game={game}
                                              badge={li.badge}></GameCard>
                                      ))
                                    : null}
                                {li.extended && li.games.length > 0 ? (
                                    <Pagination
                                        size="sm"
                                        className="home-pagination">
                                        <Pagination.Prev
                                            onClick={() =>
                                                this.handlePrevPageClick(li)
                                            }
                                            className={
                                                li.page === 1 ? ' disabled' : ''
                                            }
                                        />
                                        <Pagination.Item>
                                            {li.page}
                                        </Pagination.Item>
                                        <Pagination.Next
                                            disabled={li.totalItems <= (li.page - 1) * 5 + li.games.length ? true : false}
                                            onClick={() =>
                                                this.handleNextPageClick(li)
                                            }
                                        />
                                    </Pagination>
                                ) : null}
                            </React.Fragment>
                        ))}
                    </Col>
                </Row>
            </Container>
        );
    }

    /*
     *   Set extended and loading state; Fetch games if no games are present
     */
    handleCollapseClick = (list) => {
        const lists = [...this.state.lists];
        const index = lists.indexOf(list);

        if (lists[index].extended === false) {
            lists[index].extended = true;

            if (lists[index].games.length === 0) {
                lists[index].isLoading = true;
            }
        } else {
            lists[index].extended = false;
        }

        this.setState({ lists }, () => {
            if (lists[index].games.length === 0 && lists[index].extended)
                this.fetchGames(lists[index].name);
        });
    };

    handleNextPageClick = async (list) => {
        const lists = [...this.state.lists];
        const index = lists.indexOf(list);

        lists[index].page++;
        lists[index].games = [];
        lists[index].isLoading = true;

        this.setState({ lists },
        () => this.fetchGames(lists[index].name));
    };

    handlePrevPageClick = (list) => {
        const lists = [...this.state.lists];
        const index = lists.indexOf(list);

        lists[index].page--;
        lists[index].isLoading = true;

        this.setState({ lists },
            () => this.fetchGames(lists[index].name));
    };

    handleRefreshButtonClick = async () => {
        const lists = [...this.state.lists];
        let list = {};

        for (let i = 0; i < lists.length; i++) {
            list = lists[i];

            list.isLoading = true;
        }

        this.setState({ lists });

        for (let l of this.state.lists) {
            await this.fetchGames(l.name);
        }
    };

    fetchGames = (listName) => {
        let listPage = this.state.lists.find((li) => li.name === listName).page;

        return fetch(
            '/api/games?count=5&start=' +
                (listPage - 1) * 5 +
                '&state=' +
                listName
        )
            .then((response) => response.json())
            .then((res) => {
                if (this.mounted) {
                    this.updateList(listName, res);
                }
            })
            .catch(() => {
                if (this.mounted) {
                    this.updateList(listName, []);
                }
            });
    };

    updateList = (listName, res) => {
        const lists = [...this.state.lists];
        const list = lists.find((l) => l.name === listName);
        let newGames = [];

        /*
        Create new game element and push to list object
        */
        for (let key in res.items) {
            let newGame = res.items[key];
            newGame.id = key;

            newGames.push(newGame);
        }
        list.isLoading = false;
        list.totalItems = res.totalCount ? res.totalCount : 0;
        list.games = newGames;

        this.setState({ lists });
    };
}

export default Home;
