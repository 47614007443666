import React from 'react';
import { Button, Modal, InputGroup, Form, Alert } from 'react-bootstrap';

/**
 * Props:
 *  - onPlayer - hand up playerID
 */

class PlayerLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: null,
      show: false,
      error: null,
      alert: {
        var: null,
        show: false,
        mess: ""
      }
    }
    this.handleToken = this.handleToken.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  };

  handleShow() {

    this.setState({
      show: true,
      alert: {
        var: null,
        show: false,
        mess: ""
      }
    })
  }

  handleClose() {
    this.setState({
      show: false,
      alert: {
        var: null,
        show: false,
        mess: ""
      }
    })
  }

  handleToken() {
    var token = document.getElementById("playerTokenInputField").value;
    var show = false;
    var alert = { var: null, show: false, mess: "" };
    if (!token) return;

    fetch('/api/playerlogin', {
      method: 'Get',
      headers: new Headers({
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json'
      }),
    })
    .then(res => res.json())
    .then(res => {
      if (res.valid) {
        this.props.onPlayer({
          id: res.id,
          token: token
        });
      } else {
        alert.var = "danger";
        alert.show = true;
        alert.mess = "Invalid Token, please try again";
      }
    }).catch(error => this.setState({ error }));

    this.setState({
      token,
      show,
      alert
    });
  }

  render() {

    return (
      <>
      <Button variant="primary" onClick={this.handleShow}>
        Player Login
      </Button>
      <Button 
        variant="danger" 
        onClick={this.props.onSurrender}
        style={{display: (this.props.isParticipant ? 'inline' : 'none')}}
      >
        Surrender
      </Button>
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Body>
          <InputGroup className="mb-3" size="lg">
            <InputGroup.Prepend>
              <InputGroup.Text id="basic-addon1">Login: </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              required
              id="playerTokenInputField"
              placeholder="Player Token"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <Alert 
            variant={this.state.alert.var}
            show={this.state.alert.show}
          >
            {this.state.alert.mess}
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Dismiss
          </Button>
          <Button variant="primary" onClick={this.handleToken}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    )
  }
}

export default PlayerLogin;