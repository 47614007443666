import React, {useState, createContext, useContext} from "react";
import { ToastContext } from './ToastContext';

export const TeamDataContext = createContext();

export const TeamDataProvider = (props) => {

    const [teamData, setTData] = useState(JSON.parse(localStorage.getItem("teamData")))
    const [pushToast] = useContext(ToastContext);

    console.log("Team Provider", teamData)

    // { id:_ , token:_ , name:_ , isisName:_ , type:_ }
    const setTeamData = (obj) => {
        localStorage.setItem("teamData", JSON.stringify(obj))
        setTData(obj);
    }

    const updateTeamData = (id, token) => {
        fetch('/api/team/' + id , {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setTeamData({'id': id, 'token': token, ...data})
            pushToast({
                header: "Team Login succesful!",
                message: "Waduupp broo! Welcome back, "+ data.name + "!", 
                type: "success"
            })
        })
        .catch(err => console.log(err))
    }

    const removeTeamData = () => {
        localStorage.removeItem("teamData")
        setTData(null);
    }

    return (
        <TeamDataContext.Provider value={[teamData, setTeamData, removeTeamData, updateTeamData]}>
            { props.children }
        </TeamDataContext.Provider>
    );
}

