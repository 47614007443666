import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar from './views/NavBar';
import Home from './views/Home';
import CreateGame from './views/CreateGame';
import SingleGame from './views/SingleGame/SingleGame';
import Leaderboard from './views/Leaderboard/Leaderboard';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from './views/ToastContext';
import { TeamDataProvider } from './views/TeamDataContext';
import { PlayerDataProvider } from './views/PlayerDataContext';

function App() {

    return (
        <Router>
            <ToastProvider>
                <TeamDataProvider>
                    <PlayerDataProvider>
                        <div className="App">
                            <NavBar></NavBar>
                            <Switch>
                                <Route path="/" exact component={Home}></Route>
                                <Route
                                    path="/create"
                                    exact
                                    component={CreateGame}></Route>
                                <Route
                                    path="/game/:id"
                                    exact
                                    component={SingleGame}></Route>
                                <Route
                                    path="/leaderboard"
                                    exact
                                    component={Leaderboard}></Route>
                                <Route
                                    path="/test/:id"
                                    exact
                                    component={Leaderboard}></Route>
                            </Switch>
                        </div>
                    </PlayerDataProvider>
                </TeamDataProvider>
            </ToastProvider>
        </Router>
    );
}

export default App;
