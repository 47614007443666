import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Login from '../Login/Login';
import Logout from '../Logout/Logout';
import Profile from '../Profile/Profile';
import Notification from './Notification';
import { NavLink, withRouter } from 'react-router-dom';
import { TeamDataContext } from '../TeamDataContext';
import './index.css';

const NavBar = (props) => {
    const [teamData] = useContext(TeamDataContext);
    const [currentId, setCurrentId] = useState("");

    useEffect(()=> {
        let patharr = props.location.pathname.split("/");

        console.log(patharr);

        if(patharr[1] === "game") {
            if(sessionStorage.getItem("currentGameId") !== patharr[2]) {
                sessionStorage.setItem("currentGameId", patharr[2]);
            }

            setCurrentId(sessionStorage.getItem("currentGameId"));
        }
    }, [props]);

    return (
        <>
            <Navbar expand="lg" >
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link
                            className="px-4"
                            as={NavLink}
                            to={'/'}
                            exact
                            activeClassName="active">
                            Home
                        </Nav.Link>
                        <Nav.Link
                            className="px-4"
                            as={NavLink}
                            to={'/create'}
                            exact
                            activeClassName="active">
                            Create Game
                        </Nav.Link>
                        <Nav.Link className="px-4 single_game" as={NavLink} to={"/game/" + currentId} activeClassName="active" disabled={currentId === ""}>Current Game</Nav.Link>
                        <Nav.Link
                            className="px-4"
                            as={NavLink}
                            to={'/leaderboard'}
                            exact
                            >
                            Leaderboard
                        </Nav.Link>
                        {teamData === null ? (
                            <Login />
                        ) : (
                            <React.Fragment>
                                <Profile /> <Logout />
                            </React.Fragment>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Notification />               
        </>
    );
};

export default withRouter(NavBar);
