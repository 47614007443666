import React from 'react';
import './../GameInfo/board.css';
import Board from './Board';

const _ = require("lodash");


// Token-Notation
// white
const t1 = 'B';
const t1t1 = 'K';
const t1t2 = 'Q'; //t1 over t2
// black
const t2 = 'b';
const t2t2 = 'k';
const t2t1 = 'q'; //t2 over t1

class JumpSturdy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      squares: parseFEN(props.fen), // empty FEN in case not updated, maybe dirty!?
      lastToken: [-1, -1],
      tokenIsSelected: false,
      moves: []
    };
  }

  static getDerivedStateFromProps(props) {
		if (props.fen) {
      return {
        squares: parseFEN(props.fen),
      }
    }
		return null;
  }

  // calculate Move-String and hand it up
  sendMove(currentPos, nextPos) {
    function numToChar(num) {
      return String.fromCharCode(97 + num);
    }

    this.props.onMove(`${numToChar(currentPos[1])}${8 - currentPos[0]}${numToChar(nextPos[1])}${8 - nextPos[0]}`);
  }

  // check if target/chosen Square belongs to player
  isCorrectPlayer(squares, t) {

    const { player } = this.props;

    if (player === 1 && 
      (squares[t[0]][t[1]] && 
        squares[t[0]][t[1]].slice(0, 2) === 't1')) {
      return true;

    } else if (player === 2 && 
      (squares[t[0]][t[1]] && 
        squares[t[0]][t[1]].slice(0, 2) === 't2')) {
      return true;
    }
    return false;
  }

  // gets click number from square
  handleClick(clickedSquare) {
    //var squares = this.state.squares.slice();  // copy squares -> immutable
    const { lastToken, squares, tokenIsSelected } = this.state;

    var retState = {};

    // token is selected means that player is already validated
    if (tokenIsSelected) {

      // clicking on another Square causes a move
      // but only if player is to move
      if (!_.isEqual(lastToken, clickedSquare)) {

        // hand up move from last Token to clicked Square
        this.sendMove(lastToken, clickedSquare)
      }
      
      // if you click an the same Token again, it should just be
      // deselected
      retState = {...retState,
        tokenIsSelected: false,
        moves: []
      }

    // selects the token if board is playable and the clicked Square is a token
    // from playing character
    } else  if (this.isCorrectPlayer(squares, clickedSquare)) {
      retState = {...retState, 
        moves: this.moveGen(squares, clickedSquare),
        lastToken: clickedSquare,
        tokenIsSelected: true
      }
    }

    this.setState(retState);
  }


  // this calculates possible moves
  moveGen(squares, chosen)  {

    // calulate if a move would be within board 
    const inBoard = (x, y, dir) => {
      if ( x + dir[0] < 0 || x + dir[0] > 7 || y + dir[1] < 0 || y + dir[1] > 7 || 
           ((x + dir[0] === 0 || x + dir[0] === 7) && (y + dir[1] === 0 || y + dir[1] === 7))) {
        return false;
      }
      return true;
    }

    const isKnight = (x, y, dir) => {
      return squares[y + dir[1]][x + dir[0]].length === 4;
    }

    const isOpponent = (x, y, dir) => {
      let opponent = (squares[chosen[0]][chosen[1]].slice(0,2) === 't1' ? 't2' : 't1');
      return squares[y + dir[1]][x + dir[0]] &&
              squares[y + dir[1]][x + dir[0]].slice(0, 2) === opponent;
    }

    const isOwn = (x, y, dir) => {
      let own = squares[chosen[0]][chosen[1]].slice(0,2);
      return squares[y + dir[1]][x + dir[0]] &&
              squares[y + dir[1]][x + dir[0]].slice(0, 2) === own;
    }

    let moves = [];
    const [y, x] = chosen;

    // single directions
    let singleDir = [[-1, 0], [0, -1], [1,0]]; //[x, y]
    let singleBeatDir = [[-1, -1], [1, -1]];
    // knight directions
    let knightDir = [[-2, -1], [-1, -2], [1, -2], [2, -1]];

    // invert directions if Top player
    if (this.props.player === 2) {
      singleDir.map((e) => (e[1] *= -1));
      singleBeatDir.map((e) => (e[1] *= -1));
      knightDir.map((e) => (e[1] *= -1));
    }

    // single
    if (squares[chosen[0]][chosen[1]].length === 2) {
      for (let dir of singleDir) {
        if (inBoard(x, y, dir) &&
            !isOpponent(x, y, dir) && 
            !(isOwn(x, y, dir) &&
              isKnight(x, y, dir))) {
          moves.push([y + dir[1], x + dir[0]]);
        }
      }
      for (let dir of singleBeatDir) {
        if (inBoard(x, y, dir) &&
            isOpponent(x, y, dir)) {
          moves.push([y + dir[1], x + dir[0]]);
        }
      }

    // knight
    } else if (squares[chosen[0]][chosen[1]].length === 4) {
      for (let dir of knightDir) {
        if (inBoard(x, y, dir) &&
            !(isOwn(x, y, dir) &&
            isKnight(x, y, dir))) {
          moves.push([y + dir[1], x + dir[0]]);
        }
      }
    }

    console.log('moves: ', moves);

    return moves;
  }

  render() {
    const { squares, lastToken, tokenIsSelected, moves } = this.state;

    return (
      <div className="game">
        <div className="game-board">
          <Board 
            squares={squares}
            onClick={(i) => this.handleClick(i)}
            clicked={lastToken}
            isClicked={tokenIsSelected}
            moves={moves}
          />
        </div>
      </div>
    );
  }
}

function unifyChar(char) {

  if (char === t1) {
    return 't1';
  } if (char === t1t1) {
    return 't1t1';
  } if (char === t1t2) {
    return 't1t2';
  } if (char === t2) {
    return 't2';
  } if (char === t2t2) {
    return 't2t2';
  } if (char === t2t1) {
    return 't2t1';
  }  
}


function parseFEN(fen) {
  
  fen = fen.split(' ')[0];
  var rows = fen.split('/');

  var board = new Array(rows.length);

  for (let y = 0; y < rows.length; y++) {
    board[y] = new Array(8).fill(null);
    var chars = rows[y].split('');
    let x = 0;
    for (let i = 0; i < chars.length; i++) {
      
      // if not an number, then it must be a char
      if (isNaN(chars[i])) {
        board[y][x] = unifyChar(chars[i]);
        x++;

      // if not move on char[i] times
      } else {
        x += Number(chars[i]);
      }
    }
  }
  return board;
}

export default JumpSturdy;