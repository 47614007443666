import React, { useState, useEffect, useCallback }  from 'react';
import { Container, Col, Nav, Tab, Row, Dropdown , Button} from 'react-bootstrap';
import AccordionInfo from './AccordionInfo'
import {useParams, useLocation, Link} from "react-router-dom";

import './index.css'

const Leaderboard = () => {

    const [players, setPlayers] = useState([])
    const [games, setGames] = useState([])
    const [statistic, setStatistic] = useState([])

    // tags for dropdown
    const [tag, setT] = useState(useLocation().hash.substr(1))
    const [tags, setTags] = useState([])

    // switch mode
    const [mode, setMode] = useState('fancy')
    const [isLoading, setIsLoading] = useState(true)

    const refresh = () => {
        setIsLoading(true)
        setPlayers([])
        setGames([])
        setStatistic([])
    }

    const setTag = (t) => {
        setT(t)
    }

    const getData = () => {
        fetch('/api/players', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            //console.log("Player /api/players",data)
            setPlayers(data)
            return data
        })
        .catch(err => {
            setIsLoading(false)
            console.log(err)
        })

        fetch('/api/games', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log("GAMES /api/games",data)
            setGames(data)
            return data
        })
        .catch(err => {
            setIsLoading(false)
            console.log(err)
        })
    }

    const createStatistic = useCallback( () => {
        if (Object.entries(players).length > 0 && Object.entries(games).length > 0) {
            let tagsArray = []
            for (let [idp, player] of Object.entries(players.items)) {
                let pInfo = { id:'', completedGames:false, name:'', tags:[], team:'', type:'', games:[], white:{win:0, draw:0, lose:0}, black:{win:0, draw:0, lose:0}}
                pInfo.id = idp
                pInfo.name = player.name
                pInfo.team = player.team
                for (let [idg, game] of Object.entries(games.items)){
                    if (game.players.playerA.id === idp || game.players.playerB.id === idp) {

                        let gInfo = {id: '', result: '', completedGame: 0, resultAsPlayer:{white:{win:0, lose:0, draw:0}, black:{win:0, lose:0, draw:0}}, score:'', info:{} }
                        if (game.state.state === 'completed') {

                            /* test for tag
                            if (game.tags === undefined && game.name === 'contest1') {
                                game.tags = ['contest1', 'final']
                            
                            }
                            else if (game.tags === undefined && game.name === 'contest2') {
                                game.tags = ['contest2']
                            
                            }
                            else if (game.tags === undefined && game.name === 'ccontest3') {
                                game.tags = ['contest3']
                            
                            }
                            */

                            pInfo.completedGames = true
                           
                            let p = ''
                            if (game.players.playerA.name === player.name) {
                                p = 'playerA'
                            } else {
                                p = 'playerB'
                            }
                            if (game.state.winner === 'draw') {
                                if (p === 'playerA') {
                                    gInfo.resultAsPlayer.white.draw++
                                } else {
                                    gInfo.resultAsPlayer.black.draw++
                                }
                                gInfo.result = 'game-draw'
                                gInfo.score = 1
                            } else if (game.state.winner === p) {
                                if (p === 'playerA') {
                                    gInfo.resultAsPlayer.white.win++
                                } else {
                                    gInfo.resultAsPlayer.black.win++
                                }
                                gInfo.result = 'game-win'
                                gInfo.score = 3
                            } else {
                                if (p === 'playerA') {
                                    gInfo.resultAsPlayer.white.lose++
                                } else {
                                    gInfo.resultAsPlayer.black.lose++
                                }
      
                                gInfo.result = 'game-lose'
                                gInfo.score = 0
                            }
                            gInfo.completedGame = 1
                            gInfo.player = p
                        }

                        if (pInfo.type === '') {
                            pInfo.type = game.type
                        }

                        if (game.tags !== undefined && game.tags.length > 0) {
                            game.tags.map(t => tagsArray.push(t))
                            game.tags.map(t => pInfo.tags.push(t))
                        }
                        pInfo.tags = [...new Set(pInfo.tags)]
                        gInfo.id = idg
                        gInfo.info = game
                        pInfo.games.push(gInfo)
                    }
                }
                setStatistic(statistic => [ ...statistic, pInfo])
                setIsLoading(false)
            }
            setTags([...new Set(tagsArray)])
        }
    }, [players, games])

    useEffect(() => {

        if (players.length === 0 && games.length === 0) {
            getData()
        }
        
        if (statistic.length === 0) {
            createStatistic()
            
        }

    }, [players, games, statistic, createStatistic]);

    return (  
        <Container fluid="md">
            <h1 className="text-center m-4">Leaderboard</h1>
            <h2 className="text-center m-4">{tag === '' ? 'All Games' : '#' + tag}</h2>
            <Col md={12} lg={{ size: 8 }}>
                <Tab.Container id="left-tabs-example" defaultActiveKey="js">
                <Row>
                <Col sm={12}>
                    <Nav>
                        <Nav.Item>
                            <Nav.Link eventKey="js">Jump Sturdy</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="rk">Racing Kings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ml-auto">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                                    <g>
                                        <rect fill="none" height="24" width="24"/>
                                    </g>
                                    <g><g/>
                                    <g>
                                        <path d="M22,8l-4-4v3H3v2h15v3L22,8z"/><path d="M2,16l4,4v-3h15v-2H6v-3L2,16z"/>
                                        </g></g>
                                </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setMode('fancy')}>Fancy</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setMode('classic')}>Classic</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Item>
                        <Nav.Item>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="nav-search-button">
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                                    <g>
                                        <path d="M0,0h24 M24,24H0" fill="none"/><path d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"/>
                                        <path d="M0,0h24v24H0V0z" fill="none"/>
                                    </g>
                                </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setTag('')}><Link className="link-font-color" to={"#"}>All Games</Link></Dropdown.Item>
                                    {tags.length > 0 &&
                                        tags.map( item => {
                                            return (
                                                <Dropdown.Item onClick={() => setTag(item)}><Link className="link-font-color" to={"#" + item}>{item}</Link></Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Item>
                        <Nav.Item>
                            <Button variant="success" onClick={() => refresh()} className="nav-search-button">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px">
                                <path d="M0 0h24v24H0z" fill="none"/>
                                <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
                            </svg>
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="js">
                            <AccordionInfo type="jumpSturdy" mode={mode} statistic={statistic} tag={tag} isLoading={isLoading} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="rk">
                            <AccordionInfo type="racingKings" mode={mode} statistic={statistic} tag={tag} isLoading={isLoading} />
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
            </Col>
        </ Container>
    );
}
 
export default Leaderboard;