import React from 'react';
import { ProgressBar, ListGroup } from 'react-bootstrap';
import "./statusSpan.css";

 /**
	* TimeLeft-Props:
	* playerInfo				- Obj: id, name
	*	isPlaying				 	- true/false
	*	timeout						- total time per move in ms
	*	timebudget				- total time per Player in ms
	*	lastMoveTimestamp - ISO UTC
	*	initialTimestamp	- ISO UTC
	*/
class PlayerSpan extends React.Component {

	constructor() {
    super();
    this.state = { 
			moves: [],
			playing: false,
			globalTimePassed: 0, 	//ms
			moveTimePassed: 0,		//ms
			timer: null,
			seconds: 5,
			timeOut: false,
			showTime: {
				global: "",
				move: ""
			},
		};
    this.getTime = this.getTime.bind(this);
		this.recalc = this.recalc.bind(this);
	}
	
	parseDateToUnixTime(isoUTC) {
		return Date.parse(isoUTC);
	}

	getTime() {
		return Date.now();
	}

  secondsToTime(secs){

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      m: minutes,
      s: seconds
    };
    return obj;
  }

  recalc() {
		if (this.props.isPlaying) {

			var globalTimePassed = this.state.globalTimePassed;
			var moveTimePassed = this.state.moveTimePassed;
			
			// Check for overtime and increment a second
			if (globalTimePassed + 1000 <= this.state.timeBudget) {
				globalTimePassed += 1000;
			} else if (moveTimePassed + 1000 <= this.state.timeout) { 
				moveTimePassed += 1000;
			}
			
			// set state so a re-render happens
			this.setState({
				showTime: {
					global: this.secondsToTime(globalTimePassed / 1000),
					move: this.secondsToTime(moveTimePassed / 1000)
				},
				globalTimePassed,
				moveTimePassed
			});
		}
	}

	// iniciate timer and calculate timeBudget times
	componentDidMount() {

		var globalTimePassed = 0;
		if (this.props.moves.length > 1) {
			globalTimePassed = this.props.moves.reduce((acc, move) => 
			(move.player === this.props.player ? acc + move.details.time : acc))
		}
		this.setState({
			timer: setInterval(this.recalc, 1000),
			globalTimePassed,
		})
	}

  render() {

		// stop timer if ended
		if (this.state.timer && this.props.ended) {
			clearInterval(this.state.timer);
		}

		// color Progressbar
		var progVarOut = (this.props.timeout / this.state.moveTimePassed < 0.8 ? 'warning' : 'danger');
		progVarOut = (this.props.timeout / this.state.moveTimePassed < 0.5 ? 'success' : 'warning');
		var progVarBudget = (this.props.timeBudget / this.state.globalTimePassed < 0.9 ? 'warning' : 'danger');
		progVarBudget = (this.props.timeBudget / this.state.globalTimePassed < 0.7 ? 'info' : 'warning');

	return(
		<div>
			<div>{`Player: ${this.props.playerInfo.name} (${this.props.playerInfo.id})`}</div>
		</div>
		);
    /*return(
      <div>
				<div>{`Player: ${this.props.playerInfo.name} (${this.props.playerInfo.id})`}</div>
				<div style={{display: (this.props.lastMoveTimestamp ? 'block' : 'none')}}>
					<div>Timeout</div>
					<ProgressBar 
						max={this.props.timeout} 
						now={this.state.moveTimePassed} 
						variant={progVarOut}
						label={`${this.state.showTime.move.m} minuten ${this.state.showTime.move.s} sekunden`} />
				</div>
				<div style={{display: (this.props.lastMoveTimestamp ? 'block' : 'none')}}>
					<div>TimeBudget</div>
					<ProgressBar 
						max={this.props.timebudget} 
						now={this.state.globalTimePassed}
						variant={progVarBudget}
						label={`${this.state.showTime.global.m} minuten ${this.state.showTime.global.s} sekunden`} />
				</div>
      </div>
    );*/
  }
}


/**
 * StatusSpan-Props:
 *  playerName
 * 	initState
 *  events
 *  playable - function to notice if game is playable
 */
class StatusSpan extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			gameStatus: 'Game is planned',
			endType: null,
			info: null,
			isplayerA: true,
			lastStampPlayerA: null,
			lastStampplayerB: null
		}
	}


	static getDerivedStateFromProps(props, state) {

		var retState = {};

		// at the beginning, events or initState may be not defined or null
		if (props.initState) {

			// if moves arrived
			if (props.moves.length > 1) {

				var lastA = state.lastStampPlayerA;
				var lastB = state.lastStampPlayerB;
				retState = {...retState, gameStatus: 'Game is running'};

				// set last timestamp (if necessary)
				for (var i = props.moves.length - 1; (!lastA || !lastB) && i >= 0; i--) {
					if (!lastA && props.moves[i].player === 'playerA') {
						retState = {...retState, lastStampPlayerA: props.moves[i].timestamp};
					} else if (!lastB && props.moves[i].player === 'playerB') {
						retState = {...retState, lastStampPlayerB: props.moves[i].timestamp};
					}
				}
				
				// update isPlayer
				retState = {...retState, isplayerA: (props.moves[props.moves.length - 1].player === 'playerA'
																							? false
																							: true)};
			}

			// if an end event arrived, gameStatus has to be updated
			if (props.end) {
				let gameStatus = 'Game has ended';
				let endType = 'Reason: ';
				if (props.end.type === 'timeout') gameStatus += 'Timeout';
				if (props.end.type === 'timeBudget') gameStatus += 'timeBudget';
				if (props.end.details.type) {
					endType += props.end.details.type
				}
				if (props.end.details.winner) {
					if (props.end.details.winner === 'draw') {
						endType += ' - its a Draw!';
					} else {
						if (props.end.details.winner === 'playerA') {
							endType += ` - ${props.initState.players.playerA.name} won`;
						} else if (props.end.details.winner === 'playerB') {
							endType += ` - ${props.initState.players.playerB.name} won`;
						}
						
					}
				}
				retState = {...retState, gameStatus , endType};
			}

			//show last Server Message
			if (props.messages.length > 0) {
				retState = {...retState, info: props.messages[props.messages.length - 1]};
			}
		}
		return retState;
	}

	/**
	 * TODO
	 * 	Next Player
	 * 	Player Span (including Time left)
	 */

	render() {

		// render nothing without initial state
		if (!this.props.initState || 
			!this.props.initState.name ||
			!this.props.lastFEN) {
			return null;
		}

		// Who is logged in
		var modeClass = '';
		var mode  = `You are not participating`;
		if (this.props.playerState === 1) {
			mode = `${this.props.initState.players.playerA.name}, you are Player White`;
			modeClass = 'whitePlayer';
		} else if (this.props.playerState === 2) {
			mode = `${this.props.initState.players.playerB.name}, you are Player Black`;
			modeClass = 'blackPlayer';
		}

		// Who is to move
		var playerStatus = ' is to move';
		var playerClass = 'whitePlayer';
		if (this.props.lastFEN.split(' ')[1] === 'w') {
			playerStatus = 'White' + playerStatus;
		} else {
			playerStatus = 'Black' + playerStatus;
			playerClass = 'blackPlayer';
		}

		return (
			<ListGroup>
				<ListGroup.Item className={modeClass}>{mode}</ListGroup.Item>
				<ListGroup.Item className={playerClass}>{playerStatus}</ListGroup.Item>
				<ListGroup.Item>{this.state.gameStatus}</ListGroup.Item>
				<ListGroup.Item>{this.state.endType}</ListGroup.Item>
				<ListGroup.Item><PlayerSpan 
					player="playerA"
					playerInfo={this.props.initState.players.playerA}
					isPlaying={(this.state.isplayerA && this.state.gameStatus === 'running')}
					timeout={this.props.initState.settings.timeout}
					timeBudget={this.props.initState.settings.timebudget}
					lastMoveTimestamp={this.state.lastStampPlayerA}
					moves={this.props.moves}
					ended={(this.end ? true : false)}
					/></ListGroup.Item>
				<ListGroup.Item><PlayerSpan 
					player="playerB"
					playerInfo={this.props.initState.players.playerB}
					isPlaying={(!this.state.isplayerA && this.state.gameStatus === 'running')}
					timeout={this.props.initState.settings.timeout}
					timeBudget={this.props.initState.settings.timebudget}
					lastMoveTimestamp={this.state.lastStampPlayerB}
					moves={this.props.moves}
					ended={(this.end ? true : false)}
					/></ListGroup.Item>
			</ListGroup>
		)
	}
}

export default StatusSpan;