import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ProfileCard from './ProfileCard';
import NewPlayer from './NewPlayer';
import './index.css'

const Profile = () => {
    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Create Player Modal
    const [registerShow, setRegisterShow] = useState(false);

    const handleRegisterShow = () => setRegisterShow(true);
    const handleRegisterClose = () => setRegisterShow(false);

    return (
        <>
            <Button
                variant="outline-secondary"
                className="header-login-button mx-4"
                type="button"
                onClick={handleShow}>
                Team Profile
            </Button>
            <Modal show={show} onHide={handleClose} backdrop="static">
                {!registerShow ? (
                    <ProfileCard handleRegisterShow={handleRegisterShow} />
                ) : (
                    <NewPlayer handleRegisterClose={handleRegisterClose} />
                )}
            </Modal>
        </>
    );
};

export default Profile;
