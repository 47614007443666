import React, {useContext} from 'react';
import {Table, Modal, Card, ListGroup, ListGroupItem, Button} from "react-bootstrap";
import {TeamDataContext} from '../TeamDataContext';
import {PlayerDataContext} from '../PlayerDataContext';

const ProfileCard = (props) => {

    const [teamData] = useContext(TeamDataContext);
    const [playerData] = useContext(PlayerDataContext);

    return (
        <>
        <Modal.Header closeButton>
            <Modal.Title>Team Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Card>
            <Card.Header as="h5" bg="Secondary" >Team Information</Card.Header>
                <Card.Body>
                    
                        <Table borderless size="sm">
                            <tbody>
                                <tr>
                                    <td style={{"fontWeight": "bold"}}>Team Name:</td>
                                    <td>{teamData.name}</td>
                                </tr>
                                <tr>
                                    <td style={{"fontWeight": "bold"}}>ISIS Name:</td>
                                    <td>{teamData.isisName}</td>
                                </tr>
                                <tr>
                                    <td style={{"fontWeight": "bold"}}>Team ID:</td>
                                    <td>{teamData.id}</td>
                                </tr>
                                <tr>
                                    <td style={{"fontWeight": "bold"}}>Game Type:</td>
                                    <td>{teamData.type.replace(/([A-Z])/g, ' $1').replace(/^./, str => { return str.toUpperCase(); })}</td>
                                </tr>
                            </tbody>
                        </Table>
                </Card.Body>
            </Card>
            <br />
            <Card>
                <Card.Header as="h5">Player Information</Card.Header>
                
                {playerData.length === 0 ?
                    <Card.Body>
                        <Card.Text>
                            No Player Information available! <br/> <br/>
                            <Button variant="success" block onClick={props.handleRegisterShow}>
                                Create New Player
                            </Button>
                          
                        </Card.Text>
                    </Card.Body>
                :
                    <ListGroup variant="flush">
                    {Object.entries(playerData).map(([,obj]) => {
                        return (
                            <ListGroupItem key={obj[0]} >
                                <Table borderless size="sm">
                                    <tbody>
                                        <tr>
                                            <td style={{"fontWeight": "bold", "width": "100px"}}>Name:</td>
                                            <td>{obj[1].name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{"fontWeight": "bold", "width": "100px"}}>ID:</td>
                                            <td>{obj[0]}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </ListGroupItem>
                        );
                    })}
                    </ListGroup>
                }
                
            </Card>
            {playerData.length > 0 &&
            <>
                <hr />
                <p style={{"textAlign": "right"}}>
                    Need a new player?
                    <button className="switch-view-button" type="button" onClick={props.handleRegisterShow}>
                         Create New Player
                    </button>
                </p>
            </>
            }
        </Modal.Body>
        </>
    );
}

export default ProfileCard;