import React, {useState, createContext, useEffect, useContext, useCallback} from "react";
import {TeamDataContext} from './TeamDataContext';

export const PlayerDataContext = createContext();

export const PlayerDataProvider = (props) => {

    const [teamData] = useContext(TeamDataContext);
    const [playerData, setPData] = useState({});

    console.log("Player Provider", playerData)

    const updatePlayerData = useCallback(() => {
        fetch('/api/players', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            setPData(Object.entries(data.items).filter(([key, value]) => value.team === teamData.id))
        })
    }, [teamData]);

    // { id:_ , token:_ , Name:_ , isisName:_ , type:_ }
    const setPlayerData = (obj) => {
        localStorage.setItem("playerData", obj);
        updatePlayerData();
    };

    useEffect(() => {
        if (teamData) {
            updatePlayerData()
        }
    }, [teamData, updatePlayerData]);


    return (
        <PlayerDataContext.Provider value={[playerData, setPlayerData, updatePlayerData]}>
            { props.children }
        </PlayerDataContext.Provider>
    );
}

