import React from "react";
import { Form, Alert, Button } from 'react-bootstrap';
import "./game.css";
import MoveSpan from "./moveSpan";
import StatusSpan from "./statusSpan";
import PlayerLogin from "./playerLogin";
import JumpSturdy from "./../JumpSturdy/jumpSturdy";
import RacingKings from "./../RacingKings/racingKings";
import ReportButton from "./ReportButton";
import { ToastContext } from './../../views/ToastContext'; 

/**
 * Props:
 *   gameId
 *   playerToken
 */

class Game extends React.Component {

  static contextType = ToastContext;

  constructor(props) {
    super(props);
    this.state = {
      followMove: true,
      currentMoveNumber: 0,
      currentMove: {move: "", fen: "", valid: "", reason: ""},
      moves: [],
      end: null,
      messages: [],
      unreconevn: [],
      eventSource: null,
      initState: null,
      showError: false,
      error: null,
      gameId: null,
      lastFEN: "8/8/8/8/8/8/8/8 w - - 0 1",
      player: {
        state: 0,
        id: null,
        token: null,
      },
    };
    this.handleSendMove = this.handleSendMove.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.sendEvent = this.sendEvent.bind(this);
    this.handleSurrender = this.handleSurrender.bind(this);
    this.updateMove = this.updateMove.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleFollow = this.handleFollow.bind(this);
  }

  

  componentDidMount() {

    var gameId = this.props.gameId;
    var eventSource = this.state.eventSource;

    async function getgame(that) {
      await fetch(`/api/game/${gameId}`)
      .then((res) => res.json())
      .then((initState) =>
        that.setState({
          initState,
          moves: [
            {
              // Hard Coded initial State as Start Move (callable in MoveSpan)
              type: "initmove",
              details: {
                postFEN: initState.settings.initialFEN,
              },
            },
          ],
          currentMoveNumber: 1,
          lastFEN: initState.settings.initialFEN,
        })
      )
      .catch((err) => this.handleError(err));

      // establish SSE
      eventSource = new EventSource(
        `/api/game/${gameId}/events`
      );
      eventSource.onmessage = that.updateMove;
      eventSource.onerror = that.handleError;

      return eventSource;
    }

    getgame(this).then((eventSource) => this.setState({eventSource}));
  }

  handleError(message) {
    this.setState({
      error: message,
      showError: true,
    })
  }

  // add event to event array
  updateMove(event) {
    console.log("EVENT", event)
    const eventData = JSON.parse(event.data);
    const moves = this.state.moves.slice(); // immutable
    const messages = this.state.messages.slice();
    const { lastFEN, followMove } = this.state;

    var retState = {};

    const endtypes = [
      "timeBudget",
      "timeout",
      "gameEnd",
    ];

    // dispatch events
    if (eventData.type === "move") {
      moves.push(eventData);
      retState = {...retState, moves};

      // calc current Move Number
      if (followMove) {
        retState = {...retState, 
          currentMoveNumber: moves.length,
          lastFEN: moves[moves.length - 1].details.postFEN
        }
      }
      this.setState({})
      if (this.state.player.state === 1 && eventData.details.postFEN.split(' ')[1] === 'w') {
        this.context[0]({
          header: "Server Message",   
          message: "Its my turn!",
          type: "info"
        })
      }
      else if (this.state.player.state === 2 && eventData.details.postFEN.split(' ')[1] === 'b') {
        this.context[0]({
          header: "Server Message",   
          message: "It's my turn!",
          type: "info"
        })
      }


    } else if (eventData.type === "serverMessage") {
      messages.push(eventData);
      retState = {...retState, messages};
    } else if (endtypes.filter((type) => type === eventData.type).length > 0) {
      retState = {...retState, end: eventData};
    } else {
      this.handleError("Unrecognized Event-Type: " + eventData.type);
    }
    this.setState(retState);
  }

  // handle Move from moveSpan
  handleMove(moveIdentifier) {
    this.setState({
      lastFEN: this.state.moves[moveIdentifier].details.postFEN,
      currentMoveNumber: parseInt(moveIdentifier) + 1,
      followMove: false,
    });
  }

  sendEvent(eventObj) {

    const { gameId } = this.props;
    const { player } = this.state;

    fetch(`/api/game/${gameId}/events`, {
      method: "Post",
      headers: new Headers({
        Authorization: "Basic " + player.token,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(eventObj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.valid) {
          this.context[0]({
            header: "Server Message",   
            message: res.reason,
            type: "danger"
          })
        };
        this.setState({
          currentMove: {
            move: eventObj.details.move, 
            fen: this.state.lastFEN,
            valid: res.valid, 
            reason: res.reason,
          }
        })
        return res;
      })
      .catch((err) => this.handleError(err));
  }

  // send move from board
  async handleSendMove(move) {
    console.log('Move: ', move);
    const moveObj = {
      type: "move",
      details: {
        move,
      },
    };
    return this.sendEvent(moveObj);
  }

  // send surrender to Server
  handleSurrender() {
    const surrObj = {
      type: "surrender",
    };

    this.sendEvent(surrObj);
  }

  // handle PlayerID coming from Login
  handlePlayerid(p) {
    const { player, initState } = this.state;

    // check if player is participating
    if (p.id === initState.players.playerA.id) {
      player.state = 1;
    } else if (p.id === initState.players.playerB.id) {
      player.state = 2;
    } else {
      player.state = 0;
    }

    player.token = p.token;
    player.id = p.id;

    this.setState({
      player,
    });
  }

  // this will be invoked by follow Checkbox
  handleFollow() {
    const { followMove, moves } = this.state;
    var retState = {};

    if (!followMove && moves.length) {
      retState = {...retState,
        currentMoveNumber: moves.length,
        lastFEN: moves[moves.length - 1].details.postFEN,
      }
    }

    this.setState({...retState, followMove: !followMove})
  }

  render() {
    const { currentMoveNumber, moves, end, player, initState, 
            lastFEN, messages, followMove, showError, error, currentMove } = this.state;

    // close SSE if necessary
    if (end) {
      this.state.eventSource.close();
    }
    console.log(this.state)

    // player should not move if other player is next
    var currentPlayer = 0;

    // move only on last move and when game still running
    if (this.state.currentMoveNumber === this.state.moves.length && 
      !this.state.end &&
      lastFEN) {

      // lastFEN knows whos next
      if (lastFEN.split(' ')[1] === 'w') {
        currentPlayer = (this.state.player.state === 1 ? 1 : 0);
      } else {
        currentPlayer = (this.state.player.state === 2 ? 2 : 0);
      }
    }

    var board = <div></div>;

    // render board only if initial State arrived
    if (initState) {
      if (initState.type === "jumpSturdy") {
        board = (
          <JumpSturdy
            fen={lastFEN}
            onMove={(move) => this.handleSendMove(move)}
            player={currentPlayer}
          />
        );
      } else if (initState.type === "racingKings") {
        board = (
          <RacingKings
            fen={lastFEN}
            onMove={(move) => this.handleSendMove(move)}
            player={currentPlayer}
          />
        );
      }
    }


    return (
      <div className="game">
        {board}
        <div className="gameInfo">
          <div id="spacing" />
          <PlayerLogin
            onPlayer={(id) => this.handlePlayerid(id)}
            onSurrender={() => this.handleSurrender()}
            isParticipant={player.state > 0}
          />
          <StatusSpan
            playerState={player.state}
            lastFEN={lastFEN}
            initState={initState}
            moves={moves}
            messages={messages}
            end={end}
            playable={player === 0 ? true : false}
          />
          <Form>
            <Form.Check 
              inline
              label="Follow Moves"
              type="checkbox"
              checked={followMove}
              onChange={this.handleFollow}
            />
          </Form>
          <MoveSpan
            currMove={currentMoveNumber - 1}
            moves={moves}
            onMove={this.handleMove}
          />
          <Alert 
            variant="danger"
            onClose={() => this.setState({ showError: false })}
            style={{display: (showError ? 'block' : 'none')}}
            dismissible
            >
            <p>{error}</p>
          </Alert> 
          <ReportButton state={this.state} error={error} showError={showError} currentMove={currentMove} />
        </div>
      </div>
    );
  }
}

export default Game;
