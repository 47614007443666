import React from 'react';
import './../GameInfo/board.css';
const _ = require("lodash");

function Square(props) {
    let cl = `${props.value ? props.value : ''} sq-size `;
    if ( props.isClicked && _.isEqual(props.squareIdx, props.clicked)) {
      cl += "square clickedcolor";
    } else if (props.moves && props.moves.filter((move) => _.isEqual(move, props.squareIdx)).length > 0) {
      cl += "square movescolor";
    } else if ((props.squareIdx[0] + props.squareIdx[1]) % 2){
      cl += "square squarecolor";
    } else {
      cl += "square";
    }
    return (
      <button 
        className={cl}
        onClick={props.onClick} 
      />
    );
  }
  
function Emptysquare(props) {
    return (
      <canvas className="can sq-size">
      </canvas> 
    );
  }

class Board extends React.Component {

    renderEmptySquare() {
      return (
        <Emptysquare />
      )
    }
  
    renderSquare(y, x) {
      const { squares, clicked, isClicked, moves } = this.props;

      return (
        <Square 
          value={squares[y][x]}
          onClick={() => this.props.onClick([y, x])}
          clicked={clicked}
          isClicked={isClicked}
          squareIdx={[y, x]}
          moves={moves}
        />)
    }
  
    render() {
      return (
        <div>
          <div className="board-row">
            {this.renderSquare(0,0)}
            {this.renderSquare(0,1)}
            {this.renderSquare(0,2)}
            {this.renderSquare(0,3)}
            {this.renderSquare(0,4)}
            {this.renderSquare(0,5)}
            {this.renderSquare(0,6)}
            {this.renderSquare(0,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(1,0)}
            {this.renderSquare(1,1)}
            {this.renderSquare(1,2)}
            {this.renderSquare(1,3)}
            {this.renderSquare(1,4)}
            {this.renderSquare(1,5)}
            {this.renderSquare(1,6)}
            {this.renderSquare(1,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(2,0)}
            {this.renderSquare(2,1)}
            {this.renderSquare(2,2)}
            {this.renderSquare(2,3)}
            {this.renderSquare(2,4)}
            {this.renderSquare(2,5)}
            {this.renderSquare(2,6)}
            {this.renderSquare(2,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(3,0)}
            {this.renderSquare(3,1)}
            {this.renderSquare(3,2)}
            {this.renderSquare(3,3)}
            {this.renderSquare(3,4)}
            {this.renderSquare(3,5)}
            {this.renderSquare(3,6)}
            {this.renderSquare(3,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(4,0)}
            {this.renderSquare(4,1)}
            {this.renderSquare(4,2)}
            {this.renderSquare(4,3)}
            {this.renderSquare(4,4)}
            {this.renderSquare(4,5)}
            {this.renderSquare(4,6)}
            {this.renderSquare(4,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(5,0)}
            {this.renderSquare(5,1)}
            {this.renderSquare(5,2)}
            {this.renderSquare(5,3)}
            {this.renderSquare(5,4)}
            {this.renderSquare(5,5)}
            {this.renderSquare(5,6)}
            {this.renderSquare(5,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(6,0)}
            {this.renderSquare(6,1)}
            {this.renderSquare(6,2)}
            {this.renderSquare(6,3)}
            {this.renderSquare(6,4)}
            {this.renderSquare(6,5)}
            {this.renderSquare(6,6)}
            {this.renderSquare(6,7)}
          </div>
          <div className="board-row">
            {this.renderSquare(7,0)}
            {this.renderSquare(7,1)}
            {this.renderSquare(7,2)}
            {this.renderSquare(7,3)}
            {this.renderSquare(7,4)}
            {this.renderSquare(7,5)}
            {this.renderSquare(7,6)}
            {this.renderSquare(7,7)}
          </div>
        </div>
      );
    }
  }

  export default Board;