import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { TeamDataContext } from '../TeamDataContext';
import { ToastContext } from '../ToastContext'; // import this to toast! note the path!

const Logout = () => {
    const history = useHistory();
    const [, , removeTeamData] = useContext(TeamDataContext);
    const [pushToast] = useContext(ToastContext); // all u need is pushToast in context

    const logout = () => {
        removeTeamData();
        history.push('/');
        // this will trigger toast -> pass object {header:_ , message:_ , type:_ autoClose:_ (optional) } in pushToast. 
        // type is based on bootstrap background color! check https://getbootstrap.com/docs/4.1/utilities/colors/
        pushToast({
            header: "Team Logout successful!",   
            message: "See you next time!",
            type: "success"
        })
    };

    return (
        <Button
            className="header-login-button mx-4"
            variant="outline-danger"
            type="button"
            onClick={() => logout()}>
            Team Logout
        </Button>
    );
};

export default Logout;
