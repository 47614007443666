import React from 'react';
import './index.css';
import Badge from 'react-bootstrap/Badge';
import {Link} from "react-router-dom";

const GameCard = (props) => {
    return (
            <Link to={`game/${props.game.id}`} className="gamecard">
                <div>
                    <span className="gameName">{props.game.name}</span>
                    <span className="gameType">{getGameType(props.game.type)}</span>
                </div>
                <h4 className="ml-4">{props.game.players.playerA.name} vs. {props.game.players.playerB.name}</h4>
                <Badge className="ml-2" style={{ fontSize: '1em', fontWeight: "700", backgroundColor: props.badge.color }} variant="success" pill>
                    {props.badge.text}
                </Badge>
            </Link>
    );
};

function getGameType(type) {
    if (type === "jumpSturdy") return "Jump Sturdy";
    else if (type === "racingKings") return "Racing Kings";
    else return "Invalid";
}

export default GameCard;
