import React, { useContext } from 'react';
import { ToastContext } from '../ToastContext';
import { Toast } from 'react-bootstrap'
import './index.css'

const Notification = () => {
    

    const [ , toast, closeToast] = useContext(ToastContext);

    return (
        <>
        {toast.length > 0 &&
            toast.map((obj, i) => {
                let marginTop = (105 * i) + "px";
                return (
                    <div className="toast-div">
                        <Toast 
                        style={{marginTop: marginTop}}
                        onClose={setTimeout(() => closeToast(obj.id), obj.timeout)} 
                        onClick={() => closeToast(obj.id)}
                        show="true"
                        className={"toast-center bg-" + obj.type}
                        key={obj.id}
                        >
                            <Toast.Body> 
                                <p>
                                    <strong>{obj.header}</strong>
                                     <br /> 
                                    {obj.message}
                                </p>

                            </Toast.Body>
                        </Toast>
                    </div>
                )

            })
        }
            
         </>
    )
}

export default Notification;
