import React, { useState } from 'react';
import { Accordion, Card, Table, Button, Modal } from 'react-bootstrap';
import {Link} from "react-router-dom";
import './index'

const AccordionInfo = (props) => {

    // Modal game log
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [log, setLog] = useState([]);
    const [game, setGame] = useState({data:undefined, game:undefined});
    const [isLoading, setIsLoading] = useState(false)

    const showGameLog = (game) => {

        setIsLoading(true)

        fetch(`/api/game/${game.id}`)
        .then(response => response.json())
        .then(data => {
            console.log("DATA:",data)
            console.log("GAME:",game)
            setGame({data: data, game: game})
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err)
        })

        fetch(`/api/game/${game.id}/events`)
        .then(response => response.text())
        .then(data => {
            //console.log(data)
            let log = data.match(/{.*}/g)
            log = log.map(x => JSON.parse(x))
            console.log("LOG",log)
            setLog(log)
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false)
        })
        
        handleShow()
    }

    return (
        <>
        <Modal size="lg" show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>{isLoading === true ? 
                <xmp>loading...</xmp>
                :
                <>
                {game.data !== undefined && game.game !== undefined ?
                <>
                    <h4 className="accord-card-header">Game</h4>
                    <Table borderless size="sm">
                        <tbody>
                        <tr>
                                <td>Name (Click to View):</td>
                                <td><Link target="_blank" to={`/game/${game.game.id}`}>{game.data.name}</Link></td>
                            </tr>
                            <tr>
                                <td>ID:</td>
                                <td>{game.game.id}</td>
                            </tr>
                            <tr>
                                <td>Played as:</td>
                                <td>{game.game.player === "playerA" ? 'White' : 'Black'}</td>
                            </tr>
                            {log.length > 0 && log[log.length -1].type === 'gameEnd' &&
                            <>
                                <tr>
                                    <td>Duration:</td>
                                    <td>{ game.data.players.playerA.initialTimeBudget / 1000 + game.data.players.playerB.initialTimeBudget / 1000
                                    - Math.ceil(game.data.players.playerA.timeBudget / 1000) - Math.ceil(game.data.players.playerB.timeBudget / 1000)
                                     }s</td>

                                </tr>
                                <tr>
                                    <td>Total Moves:</td>
                                    <td>{log.length - 1}</td>

                                </tr>
                                <tr>
                                    <td>Winner:</td>
                                    <td>{log[log.length -1].details.winner === 'playerA' ? 'White' 
                                        : log[log.length -1].details.winner === 'playerB' ? 'Black' :
                                        'Draw'}</td>
                                </tr>
                                <tr>
                                    <td>Game End Reason:</td>
                                    <td>{log[log.length -1].details.type}</td>
                                </tr>
                                </>
                            }
                        </tbody>
                    </Table> 
                    <hr></hr>
                    <h4 className="accord-card-header">Player</h4>
                    <Table borderless size="sm">
                        <thead>
                            <tr>
                                <th></th>
                                <th>White Player</th>
                                <th>Black player</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>{game.data.players.playerA.name}</td>
                                <td>{game.data.players.playerB.name}</td>
                            </tr>
                            <tr>
                                <td>ID:</td>
                                <td>{game.data.players.playerA.id}</td>
                                <td>{game.data.players.playerB.id}</td>
                            </tr>
                            <tr>
                                <td>Initial Timebudget:</td>
                                <td>{game.data.players.playerA.initialTimeBudget / 1000}s</td>
                                <td>{game.data.players.playerB.initialTimeBudget / 1000}s</td>
                            </tr>
                            <tr>
                                <td>Timeout:</td>
                                <td>{game.data.players.playerA.timeout / 1000}s</td>
                                <td>{game.data.players.playerB.timeout / 1000}s</td>
                            </tr>
                            <tr>
                                <td>Time Left:</td>
                                <td>{Math.round(game.data.players.playerA.timeBudget / 1000)}s</td>
                                <td>{Math.round(game.data.players.playerB.timeBudget / 1000)}s</td>
                            </tr>
                            
                            
                            
                        </tbody>
                    </Table> 
                    </>:
                    <p>No Information</p>
                
                }
               {log.length > 0 && 
                    <>
                    <hr></hr>
                    <h4 className="accord-card-header">Game Log</h4>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                            Show me!
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                            <Card.Body> {log.map( (x, i) => <xmp>#{i}: {JSON.stringify(x)}</xmp>)}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                       
                        
                    </>
               }
                </>
            }
            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        <Accordion>
            {props.isLoading === true ?
                <center><div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </center> 
            :
            props.statistic.filter(x => props.type === x.type && (props.tag === '' || x.tags.includes(props.tag))).length === 0 ?
                <Card>
                    <Accordion.Toggle as={Card.Header}>
                        No information available
                    </Accordion.Toggle>
                </Card>
            :
            props.mode === 'fancy' ?
                props.statistic.filter(x => props.type === x.type && x.completedGames === true && (props.tag === '' || x.tags.includes(props.tag)))
                    .sort((a,b) =>  
                        b.games.filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                     }).reduce((x,y) => { if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x}, 0) - 
                        a.games.filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                     }).reduce((x,y) => { if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x}, 0)
                    )
                    .map( (item, i) => {
                    return(
                    <Card>
                        <Accordion.Toggle as={Card.Header} className={"background-rank"+i} eventKey={i}>
                            {i+1}. {item.name}
                            <span className="points">
                            {item.games.filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                     }).reduce( (x,y) => {
                        if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x
                            }, 0)} Points
                            </span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={i}>
                            <Card.Body>
                            <h3 className="accord-card-header">Statistics</h3>
                            <Table borderless responsive size="sm">
                                <tbody>
                                    <tr>
                                    <td>Team ID:</td>
                                    <td>{item.team}</td>
                                    </tr>
                                    <tr>
                                    <td>Player ID:</td>
                                    <td>{item.id}</td>
                                    </tr>
                                    <tr>
                                    <td>Type:</td>
                                    <td>{item.type}</td>
                                    </tr>
                                    <tr>
                                    <td>Completed Games:</td>
                                    <td>{item.games
                                        .filter( game => {
                                            if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                return true
                                            } else {
                                                return false
                                            }    
                                        })
                                        .reduce((x,y) => { return x + y.completedGame}, 0)}</td>
                                    </tr>
                                    <tr>
                                    <td>Result as White Player (Win/Draw/Lose):</td>
                                    <td>
                                        {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.white.win}, 0)} / {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.white.draw}, 0)} / {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.white.lose}, 0)}
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>Result as Black Player (Win/Draw/Lose):</td>
                                    <td>
                                        {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.black.win}, 0)} / {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.black.draw}, 0)} / {item.games
                                            .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                            .reduce((x,y) => { return x + y.resultAsPlayer.black.lose}, 0)}
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>Result Total (Win/Draw/Lose):</td>
                                    <td>
                                    {item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                        })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.win + y.resultAsPlayer.black.win}, 0)} / {item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.draw + y.resultAsPlayer.black.draw}, 0)} / {item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                        })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.lose + y.resultAsPlayer.black.lose}, 0)} 
                                    </td>
                                    </tr>
                                    <tr>
                                    <td>Score Total:</td>
                                    <td>{item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                        })
                                        .reduce((x,y) => { if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x}, 0)} Points</td>
                                    </tr> 
                                </tbody>
                            </Table>
                            <hr></hr>
                            <h3 className="accord-card-header">Game History</h3>
                            <Table borderless responsive size="sm">
                            <thead>
                                <tr>
                                <th>Name (Click to View)</th>
                                <th>Details</th>
                                <th>Status</th>
                                <th>W Player</th>
                                <th>B Player</th>
                                <th>Winner</th>
                                <th>Score</th>
                                </tr>
                            </thead>
                                <tbody>
                                {item.games
                                    .filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                                    })
                                    .map( game => {
                                    return (
                                        <tr>
                                            <td><Link target="_blank" to={`/game/${game.id}`}>{game.info.name}</Link></td>
                                            <td>{ game.info.state.state === 'completed' && <Link onClick={() => showGameLog(game)}><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/></svg></Link>}</td>
                                            <td>{game.info.state.state}</td>
                                            <td>{game.info.players.playerA.name}</td>
                                            <td>{game.info.players.playerB.name}</td>
                                            <td className={game.result}>{game.info.state.winner}</td>
                                            <td style={{textAlign: "right"}}>{game.score > 0 ? '+' + game.score : game.score}</td>
                                        </tr>
                                    )}
                                )}
                                </tbody>
                            </Table>
                            
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )})
            : 
            <Table bordered responsive>
                <thead>
                    <tr>
                    <th>Rank</th>
                    <th>Player</th>
                    <th>Player ID</th>
                    <th>Team ID</th>
                    <th>Completed Games</th>
                    <th>Win</th>
                    <th>Draw</th>
                    <th>Lose</th>
                    <th>Score</th>
                    </tr>
                </thead>
                <tbody>
                {props.statistic.filter(x => props.type === x.type && x.completedGames === true && (props.tag === '' || x.tags.includes(props.tag)))
                    .sort((a,b) =>  
                        b.games.filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                     }).reduce((x,y) => { if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x}, 0) - 
                        a.games.filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                     }).reduce((x,y) => { if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x}, 0)
                    )
                    .map( (item, i) => {
                    return (
                        <tr>
                            <td className={"background-rank"+i}>{i+1}</td>
                            <td>{item.name}</td>
                            <td>{item.id}</td>
                            <td>{item.team}</td>
                            <td>{item.games
                                        .filter( game => {
                                            if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                return true
                                            } else {
                                                return false
                                            }    
                                        })
                                        .reduce((x,y) => { return x + y.completedGame}, 0)}</td>
                            <td>{item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                        })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.win + y.resultAsPlayer.black.win}, 0)}</td>
                            <td>{item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                            })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.draw + y.resultAsPlayer.black.draw}, 0)}</td>
                            <td>{item.games
                                        .filter( game => {
                                                if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                                    return true
                                                } else {
                                                    return false
                                                }    
                                        })
                                        .reduce((x,y) => { return x + y.resultAsPlayer.white.lose + y.resultAsPlayer.black.lose}, 0)} </td>
                            <td>
                                {item.games
                                    .filter( game => {
                                        if (props.tag === '' || (game.info.tags !== undefined && game.info.tags.includes(props.tag))) {
                                            return true
                                        } else {
                                            return false
                                        }    
                                    })
                                    .reduce( (x,y) => {
                                        if (y.score !== undefined && y.score > 0) {
                                            return x + y.score
                                        }
                                        return x
                                    }, 0)}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            } 
            
        </Accordion>
        </>
    )
}
 
export default AccordionInfo;