import React from 'react';
import './moveSpan.css';
import { Form } from 'react-bootstrap';

/**
 * Props:
 * 	events - Event Array
 * 	handleMove - function to pass move
 */

class MoveSpan extends React.Component {

	render() {

		// Create moves JSX
		const moves = this.props.moves.map((move, moveIndex) => {
			
			var desc = 'Go to move game start';
			if (move.type === 'move') {
				desc = 'Go to move #' + moveIndex + ' - ' + move.details.move;
			}

			if (this.props.currMove === moveIndex) {
				return (
					<option key={moveIndex} value={moveIndex} selected="selected">
						{desc}
					</option>
				);
			}

			return (
				<option key={moveIndex} value={moveIndex}>
					{desc}
				</option>
			);
		});

		return (
			<Form.Group controlId="ms">
				<Form.Control 
					onChange={(e) => this.props.onMove(e.target.value)}
					as="select" 
					multiple
				>
					{moves}
				</Form.Control>
			</Form.Group>
		)
	}
}

export default MoveSpan;